import React, { useContext } from 'react';

import 'css/components/www/LandingV3/_LandingNavCTA.scss';

import { LocationContext } from 'common/containers/RouterContainer';
import Link from 'common/Link';

type Props = {
  showSignIn?: boolean;
};

const LandingNavCTA = ({ showSignIn }: Props) => {
  const location = useContext(LocationContext);

  const isLanding = location.pathname === '/';

  if (!showSignIn || !isLanding) {
    return (
      <div className="LandingNavCTA">
        <Link to="/register" className="LandingNavCTA__link LandingNavCTA__link--ghost">
          Sign up
        </Link>
        <Link to="/request-demo" className="LandingNavCTA__link LandingNavCTA__link--outlined">
          Request a demo
        </Link>
      </div>
    );
  }

  return (
    <div className="LandingNavCTA">
      <Link to="/login" className="LandingNavCTA__link LandingNavCTA__link--ghost">
        Sign in
      </Link>
      <Link to="/register" className="LandingNavCTA__link">
        Sign up
      </Link>
    </div>
  );
};

export default LandingNavCTA;
