import promisify from 'common/util/promisify';

import requestActionFactory from './requestActionFactory';
import Data from '../Data';

import type { Referrer } from 'common/api/endpoints/referralSettings';
import type { Dispatch, GetState, State } from 'redux-connect';

const {
  requestAction: referrerRequested,
  loadedAction: referrerLoaded,
  errorAction: referrerError,

  RequestType,
  LoadedType,
  ErrorType,
} = requestActionFactory<Referrer>('referrer');

export { RequestType, LoadedType, ErrorType };

// Action Creators
function fetchReferrer(referralCode: string) {
  return async (dispatch: Dispatch, getState: GetState) => {
    const { cookies } = getState();
    try {
      const response = await promisify(
        Data.fetch,
        {
          referrer: {
            input: { referralCode },
            query: Data.queries.referrer,
          },
        },
        cookies
      );
      return dispatch(referrerLoaded(response.referrer));
    } catch (e) {
      const message = typeof e === 'string' ? e : 'server error';
      return dispatch(referrerError(message));
    }
  };
}

export function loadReferrer(referralCode: string) {
  return async (dispatch: Dispatch, getState: GetState) => {
    if (!shouldFetch(getState())) {
      return;
    }

    dispatch(referrerRequested());
    return dispatch(fetchReferrer(referralCode));
  };
}

function shouldFetch(state: State) {
  return !state.referrer.data;
}
