import loadScript from 'common/util/loadScript';

const FBPixel = {
  init: function () {
    if (window.fbq) {
      return;
    }

    window.fbq = function () {
      window.fbq.callMethod
        ? window.fbq.callMethod.apply(window.fbq, arguments)
        : window.fbq.queue.push(arguments);
    };
    if (!window._fbq) {
      window._fbq = window.fbq;
    }
    window.fbq.push = window.fbq;
    window.fbq.loaded = true;
    window.fbq.version = '2.0';
    window.fbq.queue = [];

    loadScript('https://connect.facebook.net/en_US/fbevents.js', 'fb-pixel');

    window.fbq('init', '709729582515044');
  },
};

export default FBPixel;
