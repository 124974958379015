import createEntry from 'common/core/createEntry';

import CannyReducers from './canny/CannyReducers';
import CannyApp from './CannyApp';

const GA4MeasurementID = 'G-ZV9SCTX12H';
const SentryDSN = '529cd6e3ee874b109ff19dff99510d59';

createEntry({
  app: CannyApp,
  enableAdsTracking: true,
  ga4MeasurementID: GA4MeasurementID,
  reducers: CannyReducers,
  sentryDSN: SentryDSN,
});
