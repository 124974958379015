import loadScript from 'common/util/loadScript';

const RedditPixel = {
  init: function () {
    if (window.rdt) {
      return;
    }

    window.rdt = function () {
      window.rdt.sendEvent
        ? window.rdt.sendEvent.apply(window.rdt, arguments)
        : window.rdt.callQueue.push(arguments);
    };
    window.rdt.callQueue = [];

    loadScript('https://www.redditstatic.com/ads/pixel.js', 'reddit-pixel');

    window.rdt('init', 'a2_drr6jfe20sg4');
    window.rdt('track', 'PageVisit');
  },
};

export default RedditPixel;
