import { useContext, useEffect } from 'react';

import { loadReferrer } from 'common/actions/referrer';
import { LocationContext } from 'common/containers/RouterContainer';
import asyncConnect from 'common/core/asyncConnect';
import cloneElementWithProps from 'common/core/cloneElementWithProps';
import LocalStorage from 'common/storage/LocalStorage';

import type { Referrer } from 'common/api/endpoints/referralSettings';
import type { State } from 'redux-connect';

type Props = {
  referrer: Referrer;
  children: React.ReactNode;
};

const SevenDays = 7 * 24 * 60 * 60 * 1000;

const ReferrerContainer = (props: Props) => {
  // props
  const { referrer, children } = props;
  const location = useContext(LocationContext);

  useEffect(() => {
    const { referral } = location.query;

    if (!referral || !referrer) {
      return;
    }

    LocalStorage.setWithExpiry('referralCode', referral, SevenDays);
  }, [location.query, referrer]);

  return cloneElementWithProps(children, {
    ...props,
    referrer,
  });
};

export default asyncConnect(
  [
    {
      promise: ({ store: { dispatch }, location: { query } }) => {
        const referralCode = query.referral as string;

        if (!referralCode) {
          return;
        }

        return dispatch(loadReferrer(referralCode));
      },
    },
  ],
  (state: State) => ({ referrer: state.referrer?.data })
)(ReferrerContainer);
