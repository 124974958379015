import React, { useContext } from 'react';

import classnames from 'classnames';
import { Instagram, Linkedin, Youtube } from 'lucide-react';

import { LocationContext } from 'common/containers/RouterContainer';
import LazyLoadedImage from 'common/LazyLoadedImage';
import Link from 'common/Link';
import devURL from 'common/util/devURL';

import { Discord, X } from './icons';

import PurpleLogo from 'img/canny-wordmark.svg';

import 'css/components/www/_LandingFooter.scss';

const socialLinks = [
  {
    icon: <Instagram />,
    className: 'instagram',
    alt: 'Visit our Instagram page',
    url: 'https://www.instagram.com/canny.io/',
  },
  {
    icon: <>{X}</>,
    className: 'x',
    alt: 'Visit us on X',
    url: 'https://x.com/cannyhq',
  },
  {
    icon: <Linkedin />,
    className: 'linkedin',
    alt: 'Visit us on LinkedIn',
    url: 'https://www.linkedin.com/company/18048936/',
  },
  {
    icon: <>{Discord}</>,
    className: 'discord',
    alt: 'Visit our Discord',
    url: 'https://discord.gg/R89Wd8g3aY',
  },
  {
    icon: <Youtube />,
    className: 'youtube',
    alt: 'Visit our YouTube channel',
    url: 'https://www.youtube.com/@cannyio',
  },
];

type Link = {
  copy: string;
  link: string;
  newTab?: boolean;
  bold?: boolean;
};

const productLinks: Link[] = [
  { copy: 'Autopilot', link: '/features/autopilot' },
  { copy: 'Feedback', link: '/features/collect-feedback' },
  { copy: 'Analyze', link: '/features/analyze-feedback' },
  { copy: 'Roadmap', link: '/features/product-roadmap' },
  { copy: 'Changelog', link: '/features/product-changelog' },
  { copy: 'Integrations', link: '/integrations' },
];

const solutionLinks: Link[] = [
  { copy: 'Feature Request Management', link: '/use-cases/feature-request-management' },
  { copy: 'Idea Management', link: '/use-cases/idea-management' },
  { copy: 'Internal Feedback', link: '/use-cases/internal-feedback' },
  { copy: 'Product Management', link: '/use-cases/product-management' },
  { copy: 'Sales Documentation', link: '/use-cases/sales-documentation' },
  { copy: 'Public Roadmap', link: '/use-cases/public-roadmap' },
  { copy: 'Bug Tracking', link: '/use-cases/bug-tracking-tool' },
  { copy: 'Enterprise feedback', link: '/use-cases/enterprise-feedback-management' },
  { copy: 'Customer documentation', link: '/use-cases/customer-feedback' },
];

const compareLinks: Link[] = [
  { copy: 'Compare', link: '/compare', bold: true },
  { copy: 'ProductBoard', link: '/compare/productboard' },
  { copy: 'Pendo', link: '/compare/pendo' },
  { copy: 'UserVoice', link: '/compare/uservoice' },
  { copy: 'Aha!', link: '/compare/aha' },
  { copy: 'Trello', link: '/compare/trello' },
];
const learnLinks: Link[] = [
  { copy: 'Help center', link: 'https://help.canny.io', newTab: true },
  { copy: 'Customer stories', link: '/case-studies' },
  { copy: 'Podcast', link: devURL('https://productchats.canny.io'), newTab: true },
  { copy: 'Blog', link: 'https://canny.io/blog/', newTab: true },
  { copy: 'Community', link: 'https://discord.com/invite/R89Wd8g3aY', newTab: true },
  { copy: 'Give feedback', link: devURL('https://feedback.canny.io'), newTab: true },
];

const resourceLinks: Link[] = [
  { copy: 'Pricing', link: '/pricing' },
  { copy: 'About us', link: '/about' },
  { copy: 'Careers', link: 'https://careers.canny.io/', newTab: true },
  { copy: 'Email us', link: 'mailto:support@canny.io', newTab: true },
  { copy: 'Security', link: '/security' },
  { copy: 'Terms & conditions', link: '/terms' },
  { copy: 'Privacy policy', link: '/privacy' },
];

type SiteMap = { heading?: string; links: Link[] }[][];

const sitemapColumns: SiteMap = [
  [
    {
      heading: 'Product',
      links: productLinks,
    },
    {
      heading: 'Solutions',
      links: solutionLinks,
    },
  ],
  [{ links: compareLinks }, { heading: 'Learn', links: learnLinks }],
  [{ heading: 'Resources', links: resourceLinks }],
];

const LandingFooter = () => {
  const { pathname } = useContext(LocationContext);

  const whiteBackground = pathname !== '/' && pathname !== '/pricing';
  const className = 'landingFooter' + (whiteBackground ? ' white' : '');
  const currentYear = new Date().getFullYear();
  return (
    <footer className={className}>
      <div className="footerContainer">
        <div className="footerInfo">
          <Link to="/" className="cannyIconLink">
            <LazyLoadedImage
              alt="Canny logo"
              className="cannyIcon"
              src={PurpleLogo}
              height="83"
              width="300"
            />
          </Link>
          <div className="socialIcons">
            {socialLinks.map(({ icon: Icon, className, url, alt }, index) => {
              return (
                <a
                  className={'socialIcon ' + className}
                  rel="noopener noreferrer"
                  target="_blank"
                  href={url}
                  key={index}
                  aria-label={alt}>
                  {Icon}
                </a>
              );
            })}
          </div>
          <p className="text">&copy; {currentYear} Canny</p>
        </div>
        <nav className="Sitemap">
          <ul className="Sitemap__column-container">
            {sitemapColumns.map((columns, columnIndex) => {
              return (
                <li key={columnIndex} className="Sitemap__column-item">
                  {columns.map(({ heading, links }, sectionIndex) => {
                    return (
                      <ul key={sectionIndex} className="Sitemap__link-container">
                        {heading && (
                          <li>
                            <p className="Sitemap__column-heading">{heading}</p>
                          </li>
                        )}
                        {links.map(({ copy, link, newTab, bold }, linkIndex) => {
                          const copyClassNames = classnames('Sitemap__link-copy', {
                            'Sitemap__link-copy--bold': bold,
                          });
                          return (
                            <li key={linkIndex} className="Sitemap__link-item">
                              {newTab ? (
                                <a href={link} rel="noopener" target="_blank">
                                  <span className={copyClassNames}>{copy}</span>
                                </a>
                              ) : (
                                <Link to={link}>
                                  <span className={copyClassNames}>{copy}</span>
                                </Link>
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    );
                  })}
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    </footer>
  );
};

export default LandingFooter;
