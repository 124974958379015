import promisify from 'common/util/promisify';

import requestActionFactory from './requestActionFactory';
import Data from '../Data';

import type { Assessment } from 'common/api/endpoints/assessment';
import type { Dispatch, GetState, State } from 'redux-connect';

const {
  requestAction: assessmentRequested,
  loadedAction: assessmentLoaded,
  errorAction: assessmentError,

  RequestType,
  LoadedType,
  ErrorType,
} = requestActionFactory<Assessment>('assessment');

export { RequestType, LoadedType, ErrorType };

// Action Creators
function fetchAssessment(email: string) {
  return async (dispatch: Dispatch, getState: GetState) => {
    const { cookies } = getState();
    try {
      const response = await promisify(
        Data.fetch,
        {
          assessment: {
            input: {
              email,
            },
            query: Data.queries.assessment,
          },
        },
        cookies
      );
      return dispatch(assessmentLoaded(response.assessment));
    } catch (e) {
      if (typeof e === 'string') {
        return dispatch(assessmentError(e));
      }
      return dispatch(assessmentError('server error'));
    }
  };
}

export function loadAssessment(email: string) {
  return (dispatch: Dispatch, getState: GetState) => {
    if (shouldFetch(getState())) {
      dispatch(assessmentRequested());
      return dispatch(fetchAssessment(email));
    }
  };
}

// Helpers

function shouldFetch(state: State) {
  return !state.assessment.data || !state.assessment.error;
}
