import isServer from 'common/util/isServer';

type App = 'bae' | 'general' | 'landing' | 'success';
type ComponentCallback = (error: any, component?: React.ComponentType<any>) => void;

export class ImportTracker {
  trackedImports: Set<string> = new Set();

  getTrackedImports = () => {
    return [...this.trackedImports];
  };

  track = (importPath: string) => {
    if (!isServer()) {
      throw new Error('Should never track imports on the client');
    }
    this.trackedImports.add(importPath);
  };
}

export function createDynamicImport(importTracker: ImportTracker, app: App) {
  return (filename: string, options: { app?: App } = {}) => {
    const dynamicLoader = (_: any, cb: ComponentCallback) => {
      const extension = isServer() ? '.js' : '';
      const importPath = `${options.app || app}/${filename}${extension}`;
      importTracker?.track(importPath);
      import(`../routeComponents/${importPath}`).then((module) => {
        cb(null, isServer() && module?.default?.default ? module.default.default : module.default);
      });
    };
    dynamicLoader.isDynamicLoader = true;
    return dynamicLoader;
  };
}
