import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';
import { reducer as reduxAsyncConnect } from 'redux-connect';

import assessment from '../reducers/assessment';
import cookies from '../reducers/cookies';
import referrer from '../reducers/referrer';
import viewer from '../reducers/viewer';

export default combineReducers({
  cookies,
  reduxAsyncConnect,
  routing: routerReducer,
  viewer,
  assessment,
  referrer,
});
