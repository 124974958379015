import React, { Component } from 'react';

import PropTypes from 'prop-types';

import RouterContainer from 'common/containers/RouterContainer';
import asyncConnect from 'common/core/asyncConnect';
import ProductSeenContainer from 'common/growth/ProductSeenContainer';
import CannyHelmet from 'common/helmets/CannyHelmet';
import styles from 'css-module/components/www/_LandingContainer.module.scss';

import AuthContainer from './AuthContainer';
import BingContainer from './BingContainer';
import CannylyticsContainer from './CannylyticsContainer';
import EventContainer from './EventContainer';
import ExperimentContainer from './ExperimentContainer';
import HiringContainer from './HiringContainer';
import HubSpotContainer from './HubSpotContainer';
import IntercomContainer from './IntercomContainer';
import IsWidgetContainer from './IsWidgetContainer';
import LinkedInContainer from './LinkedInContainer';
import MixpanelContainer from './MixpanelContainer';
import ModalContainer from './ModalContainer';
import ViewerContainer from './ViewerContainer';

const ThirtySeconds = 30000;
const SixtySeconds = 60000;

class LandingContainer extends Component {
  static propTypes = {
    location: PropTypes.object,
    router: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.eventContainerRef = React.createRef();
  }

  componentDidMount() {
    this._isMounted = true;

    this.fixURL();
    setTimeout(() => {
      if (!this._isMounted) {
        return;
      }

      this.eventContainerRef.current.onTrackEvent('Viewed Content (30 secs)');
    }, ThirtySeconds);
    setTimeout(() => {
      if (!this._isMounted) {
        return;
      }

      this.eventContainerRef.current.onTrackEvent('Viewed Content (60 secs)');
    }, SixtySeconds);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  fixURL = () => {
    var hash = this.props.location.hash;
    if (!hash) {
      return;
    }

    if (hash.length < 4 || hash[2] !== '/') {
      return;
    }

    var url = hash.substr(3);
    this.props.router.replace(url);
  };

  renderPixels() {
    return (
      <noscript
        dangerouslySetInnerHTML={{
          __html: `
<img height="1" width="1" src="https://www.facebook.com/tr?id=709729582515044&ev=PageView&noscript=1" style="display:none" />
<img height="1" width="1" src="https://q.quora.com/_/ad/847699b9c70649cf8bca2b4820aaace2/pixel?tag=ViewContent&noscript=1" style="display:none" />
        `,
        }}
      />
    );
  }

  render() {
    return (
      <div className={styles.landingContainer}>
        <CannyHelmet />
        <RouterContainer
          location={this.props.location}
          params={this.props.params}
          router={this.props.router}>
          <IsWidgetContainer isWidget={false}>
            <ModalContainer>
              <ViewerContainer>
                <ExperimentContainer>
                  <AuthContainer>
                    <HubSpotContainer>
                      <IntercomContainer>
                        <LinkedInContainer shouldLoadSDK={true}>
                          <MixpanelContainer property="landing" trackPageViews={true}>
                            <CannylyticsContainer>
                              <BingContainer shouldLoadSDK={true}>
                                <EventContainer ref={this.eventContainerRef}>
                                  <ProductSeenContainer>
                                    <HiringContainer>{this.props.children}</HiringContainer>
                                  </ProductSeenContainer>
                                </EventContainer>
                              </BingContainer>
                            </CannylyticsContainer>
                          </MixpanelContainer>
                        </LinkedInContainer>
                      </IntercomContainer>
                    </HubSpotContainer>
                  </AuthContainer>
                </ExperimentContainer>
              </ViewerContainer>
            </ModalContainer>
          </IsWidgetContainer>
        </RouterContainer>
        <div id="fb-root" />
        {this.renderPixels()}
      </div>
    );
  }
}

export default asyncConnect([ViewerContainer.asyncConnect])(LandingContainer);
