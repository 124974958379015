import React, { Component } from 'react';

import loadScript from 'common/util/loadScript';

export const LoadVideoContext = React.createContext();
export const PlayVideoContext = React.createContext();

export default class WistiaContainer extends Component {
  state = {
    embeds: {},
  };

  componentDidMount() {
    this._shouldPlayVideo = {};
    this._videos = {};
  }

  onLoadVideo = (videoID, callback) => {
    window._wq = window._wq || [];
    window._wq.push({
      id: videoID,
      onHasData: callback,
      onReady: (video) => {
        this._videos[videoID] = video;
        if (this._shouldPlayVideo[videoID]) {
          this._shouldPlayVideo[videoID] = false;
          video.play();
        }
      },
    });

    loadScript('https://fast.wistia.com/assets/external/E-v1.js', 'wistia-script', {
      callback: () => {
        const { embeds } = this.state;
        this.setState({
          embeds: {
            ...embeds,
            [videoID]: true,
          },
        });

        loadScript(
          'https://fast.wistia.com/embed/medias/' + videoID + '.jsonp',
          'wistia-script-' + videoID
        );
      },
    });
  };

  onPlayVideo = (videoID) => {
    if (this._videos[videoID]) {
      this._videos[videoID].play();
    } else {
      this._shouldPlayVideo[videoID] = true;
    }
  };

  renderEmbeds() {
    const { embeds } = this.state;
    const videoIDs = Object.keys(embeds);
    const items = [];
    videoIDs.forEach((videoID) => {
      const className =
        'wistia_embed wistia_async_' +
        videoID +
        ' popover=true popoverContent=html popoverBoxShadow=false popoverPreventScroll=true';
      items.push(<div className={className} key={videoID} />);
    });
    return <div className="embeds">{items}</div>;
  }

  render() {
    return (
      <LoadVideoContext.Provider value={this.onLoadVideo}>
        <PlayVideoContext.Provider value={this.onPlayVideo}>
          <>
            {this.props.children}
            {this.renderEmbeds()}
          </>
        </PlayVideoContext.Provider>
      </LoadVideoContext.Provider>
    );
  }
}
