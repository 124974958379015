import React, { Component } from 'react';

import LandingNav from 'common/www/LandingV3/LandingNav';

import WistiaContainer from './containers/WistiaContainer';
import LandingFooter from './www/LandingFooter';

import 'css/components/_LandingWrapper.scss';

export default class LandingWrapper extends Component {
  render() {
    return (
      <WistiaContainer>
        <div className="landingWrapper">
          <LandingNav />
          {this.props.children}
          <LandingFooter />
        </div>
      </WistiaContainer>
    );
  }
}
